import React, { FC } from 'react';
import ROUTES from '../../constants/routes';
import Icon from '../Icon';
import LinkInternal from '../LinkInternal';
const NavbarAccount: FC<{
  firstName: string;
  lastName: string;
  setMyPlaceMobileMenuExpanded: (value: boolean) => void;
}> = ({
  firstName,
  lastName,
  setMyPlaceMobileMenuExpanded
}) => {
  return <div className="col navbar__item navbar__item--profile flex-grow-0" data-sentry-component="NavbarAccount" data-sentry-source-file="NavbarAccount.tsx">
      <LinkInternal to={ROUTES.myPlace} className="navbar__profile--wide d-none d-lg-flex pull-right" ariaLabel="Navigate to My Place" data-sentry-element="LinkInternal" data-sentry-source-file="NavbarAccount.tsx">
        <div className="navbar__item--profile-text">
          <p className="caption-title">Welcome</p>
          <h6 className="caption-title">{firstName}</h6>
        </div>
        <Icon icon="oa-user" data-sentry-element="Icon" data-sentry-source-file="NavbarAccount.tsx" />
      </LinkInternal>
      <button type="button" aria-label="toggle my place menu" data-testid="navbar__link-hamburger-my-place" className="navbar__profile--small d-flex d-lg-none justify-content-center align-items-center" onClick={() => setMyPlaceMobileMenuExpanded(true)}>
        {`${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`}
      </button>
    </div>;
};
export default NavbarAccount;