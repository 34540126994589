import React from 'react';
import type { FC } from 'react';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { navigateActions, type FocusedRoute } from '@/state/reducers/navigationSlice';
import ROUTES from '../../constants/routes';
import Icon from '../Icon';
import LinkInternal from '../LinkInternal';
const NavbarNoAccount: FC = () => {
  const dispatch = useAppDispatch();
  const {
    focusedRoute
  } = useAppSelector(state => state.navigation);

  /**
   * Workaround to pass focus on "/login" or "/register" route
   * event if the navigation occurred via "window.history.replaceState"
   * instead of using "next/navigation"
   */
  const handleIsFocused = (route: FocusedRoute) => {
    // If the focused route is undefined use window.location.href
    // instead of "usePathname"
    if (!focusedRoute && typeof window !== 'undefined') {
      return window?.location?.href?.includes(route);
    }
    return focusedRoute === route;
  };

  /**
   * Clear redux state if you use the navbar links for navigation
   */
  const handleClearForceFocus = () => {
    dispatch(navigateActions.setFocusedRoute(undefined));
  };
  return <>
      <div className="col navbar__link navbar__link--icon d-block d-lg-none flex-grow-0">
        <LinkInternal to={ROUTES.myPlace} ariaLabel="Navigate to My Place" data-sentry-element="LinkInternal" data-sentry-source-file="NavbarNoAccount.tsx">
          <Icon icon="oa-user" data-sentry-element="Icon" data-sentry-source-file="NavbarNoAccount.tsx" />
        </LinkInternal>
      </div>
      <div className="col navbar__link d-none d-lg-flex flex-grow-0">
        <LinkInternal ariaLabel="Navigate to Log in" to={ROUTES.login} isFocused={handleIsFocused(ROUTES.login)} onClick={handleClearForceFocus} data-sentry-element="LinkInternal" data-sentry-source-file="NavbarNoAccount.tsx">
          Log in
        </LinkInternal>
      </div>
      <div className="col navbar__link d-none d-lg-flex flex-grow-0">
        <LinkInternal ariaLabel="Navigate to Register" to={ROUTES.register} isFocused={handleIsFocused(ROUTES.register)} onClick={handleClearForceFocus} data-sentry-element="LinkInternal" data-sentry-source-file="NavbarNoAccount.tsx">
          Register
        </LinkInternal>
      </div>
    </>;
};
export default NavbarNoAccount;