'use client';

import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import useIsMounted from '@/hooks/use-is-mounted';
import { useAppSelector } from '@/state/hooks';
import { useEventListener } from '@/utils/useEventListener.web';
import ROUTES from '../../constants/routes';
import LinkInternal from '../LinkInternal';
import OnairLogo from '../svgs/OnairLogo';
import NavbarAccount from './NavbarAccount';
import NavbarCart from './NavbarCart';
import NavbarLeft from './NavbarLeft';
import NavbarMobile from './NavbarMobile';
import NavbarNoAccount from './NavbarNoAccount';
import NavbarSearch from './NavbarSearch';
const Navbar = () => {
  const loggedIn = useAppSelector(state => state.auth.loginStatus === 'LOGGED_IN');
  const customerInfo = useAppSelector(state => state.auth.token?.customerInfo);
  const itemsInCart = useAppSelector(state => state.cartSlice.numberOfItemsInCart);
  const [defaultMobileMenuExpanded, setDefaultMobileMenuExpanded] = useState(false);
  const [myPlaceMobileMenuExpanded, setMyPlaceMobileMenuExpanded] = useState(false);
  const [scrolledToTop, setScrolledToTop] = useState(true);
  const isMounted = useIsMounted();

  // Decide if we need to change scrollToTop
  const onScroll = useCallback(() => {
    if (window.pageYOffset === 0) setScrolledToTop(true);else setScrolledToTop(false);
  }, []);
  useEventListener('scroll', onScroll);

  // This logic makes sure we don't get hydration errors
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    setIsLoggedIn(loggedIn);
  }, [loggedIn]);
  return <header className="navbar" data-sentry-component="Navbar" data-sentry-source-file="index.tsx">
      <div className={classNames('navbar__default', {
      'navbar__default--scrolled-to-top': scrolledToTop
    })}>
        <div className="container">
          <div className="row">
            <div className="col-3 col-md-5 navbar__links">
              <div className="row">
                <NavbarLeft setDefaultMobileMenuExpanded={setDefaultMobileMenuExpanded} data-sentry-element="NavbarLeft" data-sentry-source-file="index.tsx" />
              </div>
            </div>
            <div className="col-6 col-md-2 navbar__logo">
              <LinkInternal to={ROUTES.home} ariaLabel="navigate to home page" data-sentry-element="LinkInternal" data-sentry-source-file="index.tsx">
                <OnairLogo data-sentry-element="OnairLogo" data-sentry-source-file="index.tsx" />
              </LinkInternal>
            </div>
            <div className="navbar__links--right col-3 col-md-5 navbar__links">
              <div className={classNames('row flex-nowrap justify-content-end', {
              'flex-grow-1': isLoggedIn
            })}>
                <NavbarSearch data-sentry-element="NavbarSearch" data-sentry-source-file="index.tsx" />
                {loggedIn && isMounted && customerInfo?.firstName && customerInfo.lastName ? <NavbarAccount firstName={customerInfo.firstName} lastName={customerInfo.lastName} setMyPlaceMobileMenuExpanded={setMyPlaceMobileMenuExpanded} /> : <NavbarNoAccount />}
                <NavbarCart itemsInCart={itemsInCart} data-sentry-element="NavbarCart" data-sentry-source-file="index.tsx" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <NavbarMobile loggedIn={loggedIn} isMounted={isMounted} firstName={customerInfo?.firstName} myPlaceMobileMenuExpanded={myPlaceMobileMenuExpanded} defaultMobileMenuExpanded={defaultMobileMenuExpanded} setDefaultMobileMenuExpanded={setDefaultMobileMenuExpanded} setMyPlaceMobileMenuExpanded={setMyPlaceMobileMenuExpanded} data-sentry-element="NavbarMobile" data-sentry-source-file="index.tsx" />
    </header>;
};
export default Navbar;
export const dynamic = 'force-dynamic';