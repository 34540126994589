'use client';

import type { PropsWithChildren } from 'react';
import { useIsPreviewMode } from './use-is-preview-mode';
const PreviewModeProvider = ({
  children
}: PropsWithChildren) => {
  const isPreviewMode = useIsPreviewMode();
  const handlePreviewModeExit = () => {
    if (typeof window === 'undefined') return;
    window.location = '/api/exit-draft' as any;
  };
  return <>
      {children}
      {isPreviewMode && <div onClick={handlePreviewModeExit} className="exit-preview">
          Exit preview mode
        </div>}
    </>;
};
export default PreviewModeProvider;