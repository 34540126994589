'use client';

import { useEffect, useState } from 'react';
import { LinkType } from '@/types/Notification';
import { getClient } from '@/utils/data-fetching/fetch-client';
import { fetchMenu } from '@/utils/data-fetching/fetch/fetch-menu';
import ROUTES from '../../constants/routes';
import { onAirSocialsLinks } from '../../utils/social';
import LinkExternal from '../LinkExternal';
import LinkInternal from '../LinkInternal';
import Socials from '../Socials';
import OnairLogo from '../svgs/OnairLogo';
import { Logos } from './Logos';
import messages from './messages';
export interface MenuItem {
  label: string;
  href: string;
  target?: string;
  ariaLabel?: string;
}
export interface MenuGroup {
  title: string;
  menuItems: MenuItem[];
}
const Footer = () => {
  const [menuGroups, setMenuGroups] = useState<any>([]); // Initialize as an empty array
  const client = getClient();

  // Fetch the footer menu from Contentful
  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const footerMenu = await fetchMenu({
          title: 'Footer',
          client
        });
        if (footerMenu && footerMenu.menuGroups) {
          setMenuGroups(footerMenu.menuGroups as MenuGroup[]); // Access menuGroups correctly
        }
      } catch (error) {
        console.error('Error fetching footer menu:', error);
      }
    };
    fetchMenus();
  }, []);
  return <footer className="py-4 py-md-7 footer" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <div className="container">
        <div className="d-flex flex-column">
          <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-center align-items-md-start order-2 order-md-1 footer__section-links">
            {/* Dynamically Render Menu Groups and Menu Items */}
            {menuGroups.map((group: MenuGroup, groupIndex: number) => <div key={groupIndex} className="col-lg-4 col-md-4 footer__menu-group footer__menu-links">
                <div className="footer__menu-heading">
                  <p className="footer__title">{group.title}</p>
                </div>
                <ul className="footer-text">
                  {group.menuItems.map((item: any, itemIndex: number) => <li key={itemIndex}>
                      {item.href.startsWith('http') ? <LinkExternal to={item.href} type={LinkType.LINK_STYLED_IN_COMPONENT} className="footer-link footer-text" shouldOpenInNewTab={item.target === '_blank'} shouldFollow={item.target !== '_blank'}>
                          {item.label}
                        </LinkExternal> : <LinkInternal to={item.href} shouldFollow={item.target !== '_blank'} type={LinkType.LINK_STYLED_IN_COMPONENT} className="footer-link footer-text">
                          {item.label}
                        </LinkInternal>}
                    </li>)}
                </ul>
              </div>)}
          </div>

          <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-center align-items-md-stretch order-1 order-md-2 text-align-center col-md-12">
            <div className="col-lg-4 col-md-6 col-xl-4 footer__menu-group">
              <div className="d-flex justify-content-center justify-content-md-start footer__logo">
                <LinkInternal to="/" type={LinkType.LINK_STYLED_IN_COMPONENT} className="d-flex align-items-center p-0 footer__logo__icon" ariaLabel="navigate to home page" data-sentry-element="LinkInternal" data-sentry-source-file="Footer.tsx">
                  <OnairLogo data-sentry-element="OnairLogo" data-sentry-source-file="Footer.tsx" />
                </LinkInternal>
              </div>
              <div className="d-md-flex order-3 d-none footer__socials">
                <Socials links={onAirSocialsLinks} data-sentry-element="Socials" data-sentry-source-file="Footer.tsx" />
              </div>
              <div className="d-flex justify-content-center d-md-none footer__socials">
                <Socials links={onAirSocialsLinks} data-sentry-element="Socials" data-sentry-source-file="Footer.tsx" />
              </div>
            </div>
            <div className="d-md-flex d-none">
              <Logos data-sentry-element="Logos" data-sentry-source-file="Footer.tsx" />
            </div>
          </div>
        </div>

        <div className="d-flex d-flex flex-column flex-md-row order-4 d-md-none">
          <Logos centered data-sentry-element="Logos" data-sentry-source-file="Footer.tsx" />
        </div>

        <div className="footer__bottombar-copyright d-flex flex-grow-1 justify-content-center">
          <p className="copyright-text">
            © {new Date().getFullYear()} {messages.copyrights}{' '}
            <br className="d-inline-block d-md-none" />
            <LinkInternal to={ROUTES.termsAndConditions} type={LinkType.LINK_STYLED_IN_COMPONENT} data-sentry-element="LinkInternal" data-sentry-source-file="Footer.tsx">
              {messages.terms_and_conditions}
            </LinkInternal>
            ,{' '}
            <LinkInternal to={ROUTES.privacyPolicy} type={LinkType.LINK_STYLED_IN_COMPONENT} data-sentry-element="LinkInternal" data-sentry-source-file="Footer.tsx">
              {messages.privacy_policy}
            </LinkInternal>{' '}
            &{' '}
            <LinkInternal to={ROUTES.cookiePolicy} type={LinkType.LINK_STYLED_IN_COMPONENT} data-sentry-element="LinkInternal" data-sentry-source-file="Footer.tsx">
              {messages.cookies}
            </LinkInternal>
            .
          </p>
        </div>
      </div>
    </footer>;
};
export default Footer;