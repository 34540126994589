'use client';

import { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeUserCountryCodeActions, fetchUserGeoLocationAction } from '@/reducers/geoLocationSlice';
const GeoLocationProvider = ({
  children
}: PropsWithChildren) => {
  const dispatch = useDispatch();
  const isBrowser = typeof window !== 'undefined';

  //TODO
  if (isBrowser && process.env.GATSBY_ENABLE_SET_COUNTRY_CODE === 'true') {
    // set a global function to window to be able to change geoLocation manually for test purposes
    (window as any).setCountryCode = (param: string) => {
      dispatch(changeUserCountryCodeActions.changeUserCountryCode(param));
    };
  }
  useEffect(() => {
    // TO DO - remove as any
    dispatch(fetchUserGeoLocationAction() as any);
  }, [dispatch]);
  return <>{children}</>;
};
export default GeoLocationProvider;