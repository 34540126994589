/* eslint-disable unicorn/filename-case */
import { getEnvironmentVariableServerSide as getEnvironmentServerSide } from '../../../next/src/utils/server-functions/getEnvServerSide';
import fetch from './fetch';

export async function fetchIPStack() {
  // explicit version: 'https://api.ipstack.com/check?access_key=86589cdd4d00eba11e575c2ac0dabc5c&fields=country_code';

  const { IPSTACK_ENDPOINT, IPSTACK_ACCESS_KEY } =
    await getEnvironmentServerSide(['IPSTACK_ENDPOINT', 'IPSTACK_ACCESS_KEY']);
  const endpoint = `${IPSTACK_ENDPOINT!}/check?access_key=${IPSTACK_ACCESS_KEY!}`; // In the future, some fields might be added like currency, language, etc.

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error(response.statusText ?? `${response.status}`);
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return await response.json();
}
