'use client';

import { useEffect } from 'react';
import type { PropsWithChildren } from 'react';
import { useAppSelector } from '@/state/hooks';
import { pushToElevarDataLayer } from '@/utils/pushToElevarDataLayer';
const ElevarProvider = ({
  children
}: PropsWithChildren) => {
  const auth = useAppSelector(({
    auth
  }) => auth);
  const cartTotal = useAppSelector(({
    cartSlice
  }) => cartSlice.cartTotalAmmount);
  const normalizedCartList = useAppSelector(({
    cartSlice
  }) => cartSlice.normalizedCartList);
  const isCartInitialized = useAppSelector(({
    cartSlice
  }) => cartSlice.isCartInitialized);
  useEffect(() => {
    window.ElevarInvalidateContext?.();
    window.ElevarDataLayer = window.ElevarDataLayer ?? [];
  }, []);
  useEffect(() => {
    if (isCartInitialized) {
      const products = normalizedCartList.map(({
        variantTitle,
        productTitle,
        shopifyVariantId,
        shopifyProductId,
        poster,
        price,
        compareAtPrice,
        sku
      }, index) => {
        return {
          id: sku,
          name: productTitle,
          brand: 'OnAir',
          category: 'Dummy category',
          variant: variantTitle || productTitle,
          price: price,
          compare_at_price: compareAtPrice || '0.0',
          quantity: '1',
          list: window.location.pathname,
          product_id: shopifyProductId,
          variant_id: shopifyVariantId,
          image: poster.asset.url,
          url: window.location.href,
          position: index + 1 //position in cart indexed starting at 1
        };
      });
      pushToElevarDataLayer({
        eventName: 'dl_user_data',
        auth: auth.token,
        cartTotal: cartTotal || '0',
        ecommerce: {
          currencyCode: 'EUR',
          cart_contents: {
            products
          }
        }
      });
    }
  }, [isCartInitialized]);
  return <>{children}</>;
};
export default ElevarProvider;