import { FC } from 'react';
import classNames from 'classnames';
import ROUTES from '../../constants/routes';
import Icon from '../Icon';
import LinkInternal from '../LinkInternal';
const NavbarCart: FC<{
  itemsInCart?: number;
}> = ({
  itemsInCart
}) => {
  return <div className={classNames('col navbar__link navbar__link--icon navbar__link--cart flex-grow-0', {
    'navbar__link--cart-filled': (itemsInCart || 0) > 0
  })} data-sentry-component="NavbarCart" data-sentry-source-file="NavbarCart.tsx">
      <LinkInternal to={ROUTES.cart} ariaLabel="Navigate to My Cart" data-sentry-element="LinkInternal" data-sentry-source-file="NavbarCart.tsx">
        <Icon icon="oa-cart" data-sentry-element="Icon" data-sentry-source-file="NavbarCart.tsx" />
        <span>{itemsInCart}</span>
      </LinkInternal>
    </div>;
};
export default NavbarCart;