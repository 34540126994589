'use client';

import { PropsWithChildren, useEffect } from 'react';
import { useAppSelector } from '@/state/hooks';
declare global {
  interface Window {
    // Added it for OAR-4853
    OnetrustActiveGroups?: string;
    destinationPreferences?: any;
    fcWidget?: {
      user: {
        setEmail: (email?: string) => void;
      };
      [key: string]: unknown;
    };
  }
}

/**
 *
 * @param email - User's email
 * @param attempt - Current attempt to register user
 *
 * This method is used to register the user's email to Freshchat.
 * Because Freshchat is initialized asynchronously, the window.fcWidget.user.setEmail method
 * will not be present for a user whose credentials have been fetched using autologin.
 * But it will if the use logs in using his credentials.
 * To tackle the first case we are attempting again, max 5 times to check if the method is
 * present in the window object.
 */
const registerUserToFreshChat = (email: string, attempt?: number) => {
  const maxAttempts = 5;
  const delayModifier = 1000;
  if (window?.fcWidget?.user?.setEmail) {
    window.fcWidget.user.setEmail(email);
  } else {
    const newAttemptNumber = (attempt ?? 0) + 1;
    if (newAttemptNumber >= maxAttempts) return;
    setTimeout(() => {
      registerUserToFreshChat(email, newAttemptNumber);
    }, delayModifier);
  }
};
const UserIdentifyProvider = ({
  children
}: PropsWithChildren) => {
  const customerInfo = useAppSelector(state => state.auth.token?.customerInfo);
  useEffect(() => {
    if (customerInfo?.email) {
      registerUserToFreshChat(customerInfo.email);
    }
    if (!customerInfo?.email && window?.fcWidget?.user?.setEmail) {
      // Unregister the user if he logs out
      window.fcWidget.user.setEmail(undefined);
    }
  }, [customerInfo]);
  return <>{children}</>;
};
export default UserIdentifyProvider;