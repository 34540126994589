import { FC } from 'react';
import { LinkType } from '@/types/Notification';
import ROUTES from '../../constants/routes';
import Icon from '../Icon';
import LinkExternal from '../LinkExternal';
import LinkInternal from '../LinkInternal';
const NavbarLeft: FC<{
  setDefaultMobileMenuExpanded: (value: boolean) => void;
}> = ({
  setDefaultMobileMenuExpanded
}) => {
  return <>
      <div className="d-md-flex mr-n1 col col-auto d-none navbar__link">
        <LinkInternal to={ROUTES.home} ariaLabel="Navigate to Shows" data-sentry-element="LinkInternal" data-sentry-source-file="NavbarLeft.tsx">
          Shows
        </LinkInternal>
      </div>
      <div className="d-md-flex mr-n1 col col-auto d-none navbar__link">
        <LinkInternal to={ROUTES.about} type={LinkType.LINK_STYLED_IN_COMPONENT} ariaLabel="Navigate to About" data-sentry-element="LinkInternal" data-sentry-source-file="NavbarLeft.tsx">
          About
        </LinkInternal>
      </div>
      <div className="d-md-flex col col-auto d-none navbar__link">
        <LinkExternal to={ROUTES.help} type={LinkType.LINK_STYLED_IN_COMPONENT} ariaLabel="Navigate to Help" shouldOpenInNewTab={false} data-sentry-element="LinkExternal" data-sentry-source-file="NavbarLeft.tsx">
          Help
        </LinkExternal>
      </div>
      <button type="button" aria-label="toggle main menu" className="d-flex col d-md-none navbar__link navbar__link-hamburger" onClick={() => setDefaultMobileMenuExpanded(true)} data-testid="navbar__link-hamburger">
        <Icon icon="oa-menu" data-sentry-element="Icon" data-sentry-source-file="NavbarLeft.tsx" />
      </button>
      <div className="d-lg-block col d-none" />
    </>;
};
export default NavbarLeft;